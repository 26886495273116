import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Pricing = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'pricing section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'pricing-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Career Development Pricing Plans',
    paragraph: 'Choose the plan that best suits your career goals and start your journey to success.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            {/* On-demand Coaching */}
            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="pricing-item-content">
                  <p className="text-sm mb-0">
                    Explore our on-demand coaching services for targeted advice when you need it. Ideal for interview preparation, resume critiques, and quick career questions. Pay only for the time you use.
                  </p>
                </div>
                <div className="pricing-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="pricing-item-name text-color-high">On-Demand Coaching</span>
                  <span className="text-color-low"> / </span>
                  <span className="pricing-item-link">
                    <a href="#0">Starting at $99/hour</a>
                  </span>
                </div>
              </div>
            </div>

            {/* Monthly Coaching Plan */}
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="pricing-item-content">
                  <p className="text-sm mb-0">
                    Our monthly coaching plan provides regular sessions to deep dive into your career goals, professional branding, and strategy. Includes weekly check-ins and personalized action plans.
                  </p>
                </div>
                <div className="pricing-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="pricing-item-name text-color-high">Monthly Coaching Plan</span>
                  <span className="text-color-low"> / </span>
                  <span className="pricing-item-link">
                    <a href="#0">$349/month</a>
                  </span>
                </div>
              </div>
            </div>

            {/* Comprehensive Career Package */}
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="pricing-item-content">
                  <p className="text-sm mb-0">
                    Our comprehensive package covers all aspects of career development, from crafting your resume to negotiating your offer. Tailored to professionals seeking to make a significant career leap.
                  </p>
                </div>
                <div className="pricing-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="pricing-item-name text-color-high">Comprehensive Career Package</span>
                  <span className="text-color-low"> / </span>
                  <span className="pricing-item-link">
                    <a href="#0">Contact for pricing</a>
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;
